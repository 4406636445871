<template>
	<w-layout fill-height overflow-hidden>
		<SideBar />
		<NavBar v-if="!loading" />
		<v-content v-if="!loading" fill-height full-width>
			<router-view />
			<UploadProgress />
		</v-content>
		<NPSSurveyModal></NPSSurveyModal>
		<EcmAutoRefresh></EcmAutoRefresh>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import AuthModuleGuard from '@/mixins/ModulesGuards/Auth/AuthModuleGuard'

export default {
	name: 'AuthenticatedPages',
	components: {
		NPSSurveyModal: () => ({
			component: import('@/components/Survey/NPSSurveyModal')
		}),
		NavBar: () => ({
			component: import('@/components/Navigation/NavBar')
		}),
		SideBar: () => ({
			component: import('@/components/Navigation/SideBar')
		}),
		EcmAutoRefresh: () => ({
			component: import('@/components/Settings/EcmAutoRefresh')
		}),
		UploadProgress: () => ({
			component: import('@/components/Documents/Upload/UploadProgress')
		}),
	},
	mixins: [AuthModuleGuard],
	data: function () {
		return {
			loading: true,
			title: ''
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			accountingFirm: state => state.accountingFirm.selected,
			accountingFirms: state => state.accountingFirm.list,
			vendors: state => state.company.list,
			userFullname: state => state.user.fullname
		})
	},
	watch: {
		accountingFirmId: {
			handler: function (accountingFirmId) {
				if (accountingFirmId) {
					this.setAccountingFirm(accountingFirmId)
				}
			}
		},
		vendorId: {
			handler: function (vendorId) {
				if (vendorId) {
					this.setVendor(vendorId)
				}
			}
		}
	},
	mounted: function () {
		this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
		this.loading = true
		const accountingFirmsRequest = this.appService.loadAccountingFirms().then(() => {
			let result
			if (this.accountingFirmId && this.accountingFirms.some(({ id }) => id == this.accountingFirmId)) {
				result = this.setAccountingFirm(this.accountingFirmId)
			} else if (!this.$route.params.hasOwnProperty('context')) {
				result = this.setDefaultAccountingFirm()
			} else {
				this.appService.selectDefaultAccountingFirm()
				result = this.setDefaultAccountingFirm()
			}
			return result
		})
		const userRequest = this.appService.loadUser()
		Promise.all([accountingFirmsRequest, userRequest])
			.then(() => {
				if (!this.conditionalDisplay.embedded) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('signin.messages.user_welcome', { username: this.userFullname }))
				}
			})
			.catch(error => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
			})
			.finally(() => {
				this.loading = false
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
			})
	},
	methods: {
		setAccountingFirm: function (accountingFirmId) {
			if (accountingFirmId == this.accountingFirm?.id) {
				return Promise.resolve()
			}
			const accountingFirm = this.accountingFirms.find(({ id }) => id == accountingFirmId)
			let params = this.setParams()
			params.vendor_id = this.vendorId
			return this.appService.setAccountingFirm(accountingFirm, params)
		},
		setVendor: function (vendorId) {
			const vendor = this.vendors.find(({ id }) => id == vendorId)
			this.appService.setVendor(vendor, this.setParams())
		},
		setDefaultAccountingFirm: function () {
			return this.appService.setDefaultAccountingFirm(this.setParams())
		},
		setParams: function () {
			let params = {}
			if(this.conditionalDisplay.isGenericMobileApp) {
				params = {is_generic_mobile_app: this.conditionalDisplay.isGenericMobileApp}
			} else if(this.conditionalDisplay.isMobileApp) {
				params = {is_mobile_app: this.conditionalDisplay.isMobileApp}
			} else {
				params = {is_webapp: true}
			}
			return params
		}
	}
}
</script>
